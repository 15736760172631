declare global {
    interface Window {
        dataLayer: any[];
        gtag?: (...args: any[]) => void;
    }
}

export const initGoogleTag = (tagId: string) => {
    if (typeof window !== "undefined") {
        // Adiciona o script do Google Tag
        const script = document.createElement("script");
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${tagId}`;
        document.head.appendChild(script);

        // Inicializa a dataLayer e gtag
        window.dataLayer = window.dataLayer || [];
        window.gtag = (...args: any[]) => {
            window.dataLayer.push(args);
        };
        window.gtag("js", new Date());
        window.gtag("config", tagId);
    }
};

export const trackConversion = (conversionId: string) => {
    if (typeof window !== "undefined" && window.gtag) {
        window.gtag("event", "conversion", { send_to: conversionId });
    }
};