import { SvgIcon } from '@mui/material';

function BikeRightSide() {
  return (
    <SvgIcon
      style={{ width: 84, height: 53 }}
      sx={{ fill: 'none' }}
      viewBox="0 0 84 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M68.2205 50.1106C75.8302 50.1051 81.9946 43.9317 81.9891 36.322C81.9835 28.7123 75.8102 22.5479 68.2005 22.5534C60.5908 22.559 54.4264 28.7324 54.4319 36.3421C54.4375 43.9518 60.6108 50.1161 68.2205 50.1106Z"
        stroke="#A547FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8143 50.4373C23.424 50.4317 29.5884 44.2583 29.5828 36.6486C29.5773 29.0389 23.4039 22.8746 15.7942 22.8801C8.18451 22.8856 2.02012 29.059 2.02567 36.6687C2.03121 44.2784 8.20459 50.4428 15.8143 50.4373Z"
        stroke="#A547FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.2101 36.3333L56.2148 7.45214L55.6382 4.04892L58.1382 2.01722"
        stroke="#A547FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.2148 7.45149L32.767 19.1968L15.8025 36.6579L40.4486 37.2551L58.5974 13.1908"
        stroke="#A547FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.612 13.0081L35.5715 12.0174M30.7535 12.554L32.7677 19.1958L40.4493 37.2541L30.7535 12.554Z"
        stroke="#A547FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse cx="41.6742" cy="36.7537" rx="3.38314" ry="3.38314" fill="#A547FF" />
      <ellipse cx="16.7612" cy="35.8311" rx="2.46046" ry="2.46046" fill="#A547FF" />
      <path d="M42.2891 37.9839L42.2891 41.367" stroke="#A547FF" />
      <path d="M40.752 41.9819L43.8275 41.9819" stroke="#A547FF" strokeWidth="2" />
      <path d="M17.377 36.4463L39.5211 37.0614" stroke="#A547FF" strokeWidth="4" />
    </SvgIcon>
  );
}

export default BikeRightSide;
