import axios, { AxiosInstance } from 'axios';
import TokenService from '~/services/token';
import { storeStateLoading } from '~/store/slices/loading';
// import mock from './useMock'

// mock(api)

const createApiInstance = (version: string = 'v1') => {
  const api = axios.create({
    baseURL: `${process.env.REACT_APP_ARIDE_API_URL}/${version}`
  });

  api.interceptors.request.use(
    (config) => {
      const authData = TokenService.getClientCredencialtoken();
      if (config && authData) {
        config.headers = {
          Authorization: 'Bearer ' + authData
        };
      }
      storeStateLoading.startLoading();
      return config;
    },
    (error) => {
      storeStateLoading.stopLoading();
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      storeStateLoading.stopLoading();
      return response;
    },
    (error) => {
      storeStateLoading.stopLoading();
      return Promise.reject(error);
    }
  );

  return api;
};

interface ApiInstance extends AxiosInstance {
  v2?: AxiosInstance;
}

const api = createApiInstance() as ApiInstance;
api.v2 = createApiInstance('v2');

export default api;
