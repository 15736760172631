import { Grid } from '@mui/material';
import BikeFrontSide from '~/assets/icons/BikeFrontSide';
import BikeFrontalWheel from '~/assets/icons/BikeFrontWheel';
import BikeLeftSide from '~/assets/icons/BikeLeftSide';
import BikeRearWheel from '~/assets/icons/BikeRearWheel';
import BikeRightSide from '~/assets/icons/BikeRightSide';
import FileIcon from '~/assets/icons/File';
import FileImage from '~/assets/icons/FileImage';
import FrontGearShift from '~/assets/icons/FrontGearShift';
import RatchetsGroupIcon from '~/assets/icons/RatchetsGroupIcon';
import RearGearShift from '~/assets/icons/RearGearShift';
import SerialIcon from '~/assets/icons/SerialIcon';
import InputFile from '~/components/InputFile';
import { IFile } from '~/services/modules/contract/types';
import { FilesError, TypeFile } from '../..';

type SuraNotNewProps = {
  onChangeFile: (key: TypeFile, file: IFile) => void;
  errors: FilesError;
  equipamentTypeId: number;
};

export default function SuraNotNew({ onChangeFile, errors, equipamentTypeId }: SuraNotNewProps) {
  return (
    <>
      <Grid item xs={12} sm={6} display={'flex'} alignItems={'flex-end'}>
        <InputFile
          error={errors?.invoice}
          label="Nota Fiscal"
          onChangeFile={(file) => onChangeFile('invoice', file)}
          baseIcon={<FileIcon />}
          alternateIcon={<FileImage />}
        />
      </Grid>
      <Grid item xs={12} sm={6} display={'flex'} alignItems={'flex-end'}>
        <InputFile
          error={errors?.overview}
          label="Número de série"
          baseIcon={<SerialIcon />}
          alternateIcon={<FileImage />}
          onChangeFile={(file) => onChangeFile('serial_number_photo', file)}
        />
      </Grid>
      <Grid item xs={12} sm={6} display={'flex'} alignItems={'flex-end'}>
        <InputFile
          error={errors?.serial_number_photo}
          label="Visão geral do equipamento (lado direito)"
          onChangeFile={(file) => onChangeFile('right_side_overview', file)}
          baseIcon={<BikeRightSide />}
          alternateIcon={<FileImage />}
        />
      </Grid>
      <Grid item xs={12} sm={6} display={'flex'} alignItems={'flex-end'}>
        <InputFile
          error={errors?.ratchets_group}
          label="Visão geral do equipamento (lado esquerdo)"
          onChangeFile={(file) => onChangeFile('left_side_overview', file)}
          baseIcon={<BikeLeftSide />}
          alternateIcon={<FileImage />}
        />
      </Grid>
      <Grid item xs={12} sm={6} display={'flex'} alignItems={'flex-end'}>
        <InputFile
          error={errors?.ratchets_group}
          label="Grupo de catracas"
          onChangeFile={(file) => onChangeFile('ratchets_group', file)}
          baseIcon={<RatchetsGroupIcon />}
          alternateIcon={<FileImage />}
        />
      </Grid>
      <Grid item xs={12} sm={6} display={'flex'} alignItems={'flex-end'}>
        <InputFile
          error={errors?.ratchets_group}
          label="Roda dianteira"
          onChangeFile={(file) => onChangeFile('front_wheel', file)}
          baseIcon={<BikeFrontalWheel />}
          alternateIcon={<FileImage />}
        />
      </Grid>
      <Grid item xs={12} sm={6} display={'flex'} alignItems={'flex-end'}>
        <InputFile
          error={errors?.ratchets_group}
          label="Roda traseira"
          onChangeFile={(file) => onChangeFile('rear_wheel', file)}
          baseIcon={<BikeRearWheel />}
          alternateIcon={<FileImage />}
        />
      </Grid>
      <Grid item xs={12} sm={6} display={'flex'} alignItems={'flex-end'}>
        <InputFile
          error={errors?.ratchets_group}
          label="Frontal"
          onChangeFile={(file) => onChangeFile('front', file)}
          baseIcon={<BikeFrontSide />}
          alternateIcon={<FileImage />}
        />
      </Grid>
      {equipamentTypeId !== 4 && (
        <Grid item xs={12} sm={6} display={'flex'} alignItems={'flex-end'}>
          <InputFile
            error={errors?.ratchets_group}
            label="Câmbio dianteiro"
            onChangeFile={(file) => onChangeFile('front_gear_shift', file)}
            baseIcon={<FrontGearShift />}
            alternateIcon={<FileImage />}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6} display={'flex'} alignItems={'flex-end'}>
        <InputFile
          error={errors?.ratchets_group}
          label="Câmbio traseiro"
          onChangeFile={(file) => onChangeFile('rear_gear_shift', file)}
          baseIcon={<RearGearShift />}
          alternateIcon={<FileImage />}
        />
      </Grid>

      <Grid item xs={12} sm={6} display={'flex'} alignItems={'flex-end'}>
        <InputFile
          error={errors?.ratchets_group}
          label="Outra foto (opcional)"
          onChangeFile={(file) => onChangeFile('ratchets_group', file)}
          baseIcon={<FileIcon />}
          alternateIcon={<FileImage />}
        />
      </Grid>
    </>
  );
}
