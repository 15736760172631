import { SvgIcon } from '@mui/material';

function BikeFrontSide() {
  return (
    <SvgIcon style={{ width: 36, height: 55 }} viewBox="0 0 36 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.0251 52.0369L18.6133 2.00049"
        stroke="#A547FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.1113 13.5L21.1113 13.5" stroke="#A547FF" strokeWidth="8" />
      <path d="M14.6211 40.499L23.1211 40.499" stroke="#A547FF" strokeWidth="2" />
      <path d="M17.4043 15.499L15.1211 41.1074" stroke="#A547FF" />
      <path d="M20.1211 15.499L22.4043 41.1074" stroke="#A547FF" />
      <path d="M1.11133 1L36.1113 1" stroke="#A547FF" strokeWidth="2" strokeLinecap="round" />
    </SvgIcon>
  );
}

export default BikeFrontSide;
