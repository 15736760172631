import { SvgIcon } from '@mui/material';

function BikeFrontalWheel() {
  return (
    <SvgIcon
      style={{ width: 84, height: 53 }}
      sx={{ fill: 'none' }}
      viewBox="0 0 84 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M68.2205 50.1105C75.8301 50.1049 81.9945 43.9316 81.989 36.3219C81.9834 28.7122 75.8101 22.5479 68.2004 22.5534C60.5907 22.559 54.4264 28.7323 54.4319 36.342C54.4375 43.9517 60.6108 50.116 68.2205 50.1105Z"
        stroke="#A547FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8142 50.4371C23.4239 50.4316 29.5882 44.2582 29.5827 36.6486C29.5772 29.0389 23.4038 22.8746 15.7941 22.8801C8.18448 22.8856 2.02012 29.059 2.02567 36.6687C2.03121 44.2783 8.20456 50.4427 15.8142 50.4371Z"
        stroke="#A6A6A6"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.21 36.3332L56.2148 7.45212L55.6382 4.04891L58.1382 2.01722"
        stroke="#A6A6A6"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.2146 7.45149L32.7669 19.1967L15.8025 36.6578L40.4485 37.2549L58.5973 13.1908"
        stroke="#A6A6A6"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.612 13.0076L35.5715 12.0169M30.7535 12.5535L32.7677 19.1953L40.4492 37.2535L30.7535 12.5535Z"
        stroke="#A6A6A6"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="41.6741" cy="36.7537" r="3.38312" fill="#A6A6A6" />
      <ellipse cx="16.3027" cy="36.3706" rx="3" ry="3" fill="#A6A6A6" />
      <path d="M42.2891 37.9839L42.2891 41.367" stroke="#A6A6A6" />
      <path d="M40.752 41.9819L43.8275 41.9819" stroke="#A6A6A6" strokeWidth="2" />
      <path d="M16.9922 36.2085L41.0059 36.7912" stroke="#A6A6A6" strokeWidth="5" />
    </SvgIcon>
  );
}

export default BikeFrontalWheel;
