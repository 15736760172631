import { SvgIcon } from '@mui/material';

function BikeLeftSide() {
  return (
    <SvgIcon
      style={{ width: 84, height: 53 }}
      sx={{ fill: 'none' }}
      viewBox="0 0 84 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7795 50.1106C8.16976 50.1051 2.00537 43.9317 2.01092 36.322C2.01646 28.7123 8.18984 22.5479 15.7995 22.5534C23.4092 22.559 29.5736 28.7324 29.5681 36.3421C29.5625 43.9518 23.3892 50.1161 15.7795 50.1106Z"
        stroke="#A547FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.1857 50.4373C60.576 50.4317 54.4116 44.2583 54.4172 36.6486C54.4227 29.0389 60.5961 22.8746 68.2058 22.8801C75.8155 22.8856 81.9799 29.059 81.9743 36.6687C81.9688 44.2784 75.7954 50.4428 68.1857 50.4373Z"
        stroke="#A547FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7899 36.3333L27.7852 7.45214L28.3618 4.04892L25.8618 2.01722"
        stroke="#A547FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.7852 7.45149L51.233 19.1968L68.1975 36.6579L43.5514 37.2551L25.4026 13.1908"
        stroke="#A547FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.388 13.0081L48.4285 12.0174M53.2465 12.554L51.2323 19.1958L43.5507 37.2541L53.2465 12.554Z"
        stroke="#A547FF"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="3.38314"
        cy="3.38314"
        rx="3.38314"
        ry="3.38314"
        transform="matrix(-1 0 0 1 45.709 33.3706)"
        fill="#A547FF"
      />
      <ellipse
        cx="2.46046"
        cy="2.46046"
        rx="2.46046"
        ry="2.46046"
        transform="matrix(-1 0 0 1 69.6992 33.3706)"
        fill="#A547FF"
      />
      <path d="M41.7109 37.9839L41.7109 41.367" stroke="#A547FF" />
      <path d="M43.248 41.9819L40.1725 41.9819" stroke="#A547FF" strokeWidth="2" />
      <path d="M66.623 36.4463L44.4789 37.0614" stroke="#A547FF" strokeWidth="4" />
    </SvgIcon>
  );
}

export default BikeLeftSide;
