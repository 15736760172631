import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '~/services/api';
import { storeState } from '~/store';

export default function ContractDownload() {
  const navigate = useNavigate();
  const { contractHash } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const downloadContract = async () => {
    const contractId = (await api.v2?.get(`/contracts/${contractHash}`))?.data?.data?.contract_id;
    try {
      setIsLoading(true);
      const response = await api.v2?.get(`/contracts/${contractHash}/download`, {
        responseType: 'blob'
      });

      const blob = new Blob([response?.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `contrato_${contractId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      storeState.addToast({
        title: 'Erro ao baixar contrato',
        message: 'Não foi possível baixar o contrato. Tente novamente mais tarde.',
        type: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    downloadContract();
  }, []);

  return (
    <Box
      sx={{
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        paddingTop: '8rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        alignItems: 'center',
        justifyContent: 'flex-start',
        maxWidth: 520
      }}
    >
      <Typography variant="h1" fontSize={28} fontWeight="bold" textAlign="center">
        Download do Contrato
      </Typography>

      <Typography variant="h3" fontSize={24} fontWeight="bold" paddingTop="1rem" textAlign="center">
        O download do contrato será iniciado automaticamente em instantes. Caso o processo não comece, clique no botão
        abaixo para fazer o download manualmente.
      </Typography>

      <LoadingButton
        variant="contained"
        onClick={downloadContract}
        disabled={isLoading}
        sx={{ minWidth: '60%', marginTop: 2 }}
        loading={isLoading}
      >
        Baixar contrato
      </LoadingButton>
      <Typography variant="subtitle2" fontSize={20} textAlign="center" fontWeight="300">
        Se tiver alguma dúvida ou encontrar dificuldades, entre em contato com nossa equipe de suporte.
      </Typography>
    </Box>
  );
}
